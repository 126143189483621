export const tableOption = {
    border: true,
    stripe: true,
    searchMenuSpan: 6,
    align: 'center',
    menu:true,
    menuWidth: 120,
    height: 'auto',
    fit: true,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    searchLabelWidth: 80,
    column: [{
        label: 'ID',
        prop: 'id',
        width: 200,
    },{
        label: 'UID',
        prop: 'uid',
        hide: true
    },{
        label: '风险等级',
        prop: 'riskLevel',
        type: 'radio',
        width: 200,
        dicData: [{
          label:  '低风险',
          value: 1
        }, {
          label:  '中风险',
          value: 2
        }, {
            label:  '高风险',
            value: 3
          }],
    },{
        label: '内容',
        prop: 'content',
    },{
        label: '预警类型',
        prop: 'monitorCode',
        type: 'select',
        props: {
          label: 'monitorName',
          value: 'monitorCode'
        },
        dicMethod: 'post',
        dicUrl: '/dms/cms/monitor_alarm/page',
        dicFormatter: (res)=>{
            return res.data.records
        }
    },{
        label: '状态',
        prop: 'status',
        type: 'radio',
        width: 200,
        dicData: [{
          label:  '未处理',
          value: 0
        }, {
          label:  '已处理',
          value: 1
        },],
    },{
      label: '原因',
      prop: 'dealRemark',
      overHidden: true,
      width: 150,
  },{
        label: '预警时间',
        prop: 'alarmTime',
        type: "datetimerange",
        width: 170,
        defaultTime:['00:00:00', '23:59:59'],
        searchRange: true
    }
],
}