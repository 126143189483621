<template>
    <div class="earlyWarningRules">
        <basicContainer>
            <fromSearch :object_='seriesList_' @searchFun='getList'  />
            <avue-crud ref="crud" :page="page" :data="page.list"
                :table-loading="listLoading" :option="tableOption1"
                @size-change="sizeChange" @current-change="currentChange">
                <template #alarmTime="scope">
                    <div class="fail-row" v-if="scope.row.riskLevel === 3">
                        {{turnTimeFun(scope.row.alarmTime)}}
                    </div>
                    <div class="warning-row" v-else-if="scope.row.riskLevel === 2">
                        {{turnTimeFun(scope.row.alarmTime)}}
                    </div>
                    <div class="success-row" v-else>
                        {{turnTimeFun(scope.row.alarmTime)}}
                    </div>
                </template>
                <template #monitorCode="scope">{{scope.row.monitorName}}</template>
                <!-- <template #status="scope" >
                    <el-switch v-model="scope.row.status" :disabled="scope.row.status === 1"
                        active-text="已处理" inactive-text="未处理" :active-value="1"
                        :inactive-value="0" @change="changeValue(scope.row)" />
                </template> -->
                <template #menu="scope">
                    <el-button @click="lookView(scope.row)" type="text" v-if="scope.row.monitorCode === 'WALLET_ADDRESS_CHANGE' || scope.row.monitorCode === 'RECHARGED_COIN_NOT_COLLECTED'">查看</el-button>
                    <el-button @click="updateView(scope.row)" type="text" v-if="scope.row.status === 0">处理</el-button>
                </template>

            </avue-crud>
            <el-dialog title="提示" v-model="dialogVisible_" width="30%">
                <div class="grid_">
                    <span class="item0">确认要将此日志标记为【已处理】吗？</span>
                    <span class="item1">备注：</span>
                    <el-input placeholder="请输入原因" v-model="dealRemark" class="item2" type="textarea"></el-input>
                </div>
                <template #footer>
                    <el-button type="primary" @click="changeValue">提交</el-button>
                </template>
            </el-dialog>
            <el-dialog title="详情" v-model="dialogVisible_1" width="30%">
                <div class="grid_1">
                    <div class="tit_">
                        <div>预计策略</div>
                    </div>
                    <div class="tit_d">
                        <div>风险等级</div>
                        <div v-if="detail_data.riskLevel === 1" style="color:#93c6f8">低风险</div>
                        <div v-else-if="detail_data.riskLevel === 2" style="color:#f6cb8c">中风险</div>
                        <div v-else-if="detail_data.riskLevel === 3" style="color:rgb(247, 144, 144)">高风险</div>
                    </div>
                    <div class="tit_d">
                        <div>预警类型</div>
                        <div v-if='detail_data.monitorCode === "WALLET_ADDRESS_CHANGE"'>{{ detail_data.alarmTypeDesc }}地址变更</div>
                        <div v-else>{{ detail_data.monitorName }}</div>
                    </div>
                    <div class="tit_d" v-if="detail_data.monitorCode ==='RECHARGED_COIN_NOT_COLLECTED'">
                        <div>触发条件</div><div class='long_'>
                            <span v-if='detail_data.amountValue > 0'>充币金额折合{{ detail_data.amountValue }} USDT 且</span>
                            <span>归集时间大于{{ detail_data.timeValue }}分钟</span>
                        </div>
                    </div>
                    <template v-if="detail_data.monitorCode ==='RECHARGED_COIN_NOT_COLLECTED'">
                        <div class="tit_" style="margin-top: 30px;">
                            <div>客户信息</div>
                        </div>
                        <div class="tit_d">
                            <div>UID</div><div>{{ detail_data.userId }}</div>
                        </div>
                        <div class="tit_d">
                            <div>链</div><div>{{ detail_data.chain }}</div>
                        </div>
                        <div class="tit_d">
                            <div>数量</div><div>{{ detail_data.rechargeAmount}}</div>
                        </div>
                        <div class="tit_d">
                            <div>充币时间</div><div>{{ detail_data.rechargeTime }}</div>
                        </div>
                        <div class="tit_d">
                            <div>未归集时间</div><div>{{ turn_(detail_data.uncollectedSeconds) }}</div>
                        </div>
                        <div class="tit_d">
                            <div>充币订单号</div><div>{{ detail_data.rechargeOrderNo}}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="tit_" style="margin-top: 30px;">
                            <div>变更信息</div>
                        </div>
                        <div class="tit_d" v-if='detail_data.chain'>
                            <div>链</div><div>{{ detail_data.chain }}</div>
                        </div>
                        <!-- <div class="tit_d">
                            <div>币种</div><div>{{detail_data.coin}}</div>
                        </div> -->
                        <div class="tit_d">
                            <div>更改前地址</div><div>{{ detail_data.oldWalletAddress}}</div>
                        </div>
                        <div class="tit_d">
                            <div>更改后地址</div><div>{{ detail_data.newWalletAddress}}</div>
                        </div>
                        <div class="tit_d">
                            <div>更改时间</div><div>{{ turnTimeFun(detail_data.walletAddressChangeTime)}}</div>
                        </div>

                    </template>
                </div>
                <template #footer>
                    <el-button type="primary" @click="dialogVisible_1 = false">关闭</el-button>
                </template>
            </el-dialog>
        </basicContainer>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import Api_ from '@/api/index'
    import fromSearch from '@/components/fromSearch.vue'
import { tableOption } from "@/const/crud/monitor-early-warning/monitor-role.js";
// import moment from 'moment'
    import { ElNotification } from 'element-plus'
    import { monitorRole } from '@/const/from/record/customerWallet.js'
    const seriesList_ = ref(monitorRole);
    import { turnTimeFun } from '@/utils/util.js'

import basicContainer from '../compontents/main.vue'
// const parmsSearchParm = {alarmTime:['startAlarmTime','endAlarmTime']}

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['monitorAlarmRecordPage'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const dialogVisible_1 = ref(false)
    const tableOption1 = ref(tableOption);
    const detail_data = ref({})

    const lookView = (item)=>{
        dialogVisible_1.value = true
        Api_.monitorAlarmRecordPageDetails(item.id).then(res=>{
            if(res.code === 0){
                detail_data.value = res.data
            }
        })
    }
    const dialogVisible_ = ref(false);
    const dealRemark = ref('');
    const items = ref({})
    const updateView = (item)=>{
        dialogVisible_.value = true;
        dealRemark.value = ''
        items.value = item
    }
    const turn_ = (seconds)=>{
        var hours = Math.floor(seconds / 3600);  
        var minutes = Math.floor((seconds % 3600) / 60);  
        var remainingSeconds = seconds % 60;  
        return hours + "小时 " + minutes + "分钟 " + remainingSeconds + "秒";  
    }
    const changeValue = async ()=>{
        if(items.value.status === 0){
            listLoading.value = true;
            const data = await Api_.monitorAlarmRecordPageEdit({id:items.value.id,status:1,dealRemark: dealRemark.value});
            listLoading.value = false;
            dialogVisible_.value = false
            if(data.code === 0){
                getList()
                ElNotification({
                    message: '修改成功',
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: data.msg,
                    type: 'error',
                })
            }
        }

    }
    getList(1)
</script>
<style scoped >

.grid_{
    margin-top: 5px;
    display: grid;
    grid-template-columns: 50px 300px;
    grid-template-rows: 30px 30px;
    grid-gap:1px;
    .item0{
        grid-column: span 2;
        grid-row: 1;
    }
    .item1{
        grid-column: 1;
        grid-row: 2;
    }
    .item2{
        grid-column: 2;
        grid-row: 2;
    }
}
.el-table .fail-row {
  background: rgb(247, 144, 144);
  color: #fff;height: 100%;
}

.el-table .warning-row {
  background: #f6cb8c;
  color: #fff;
}
.el-table .success-row {
  background: #93c6f8;
  color: #fff;
}
.grid_1{
    .tit_{
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .tit_d{
        margin-bottom: 5px;
        display: flex;align-items: center;justify-content: space-between;
        
    }
}
</style>