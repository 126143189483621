import { relationType,withdrawStatus,collectionStatus } from "@/utils/record"
import { fromSearchFore } from '@/const/publicData/record'
export const topUp_ = [{
    type: 'input',
    name: 'ID',
    prop: 'recordId',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '客户地址',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'select',
    name: '状态',
    prop: 'status',
    postData:[
        { value: '1',label:'待确认' },
        { value: '2',label:'已确认' },
        { value: '3',label:'确认失败' },
    ]
},{
    type: 'select',
    name: '是否已归集',
    prop: 'collectionStatus',
    postData: collectionStatus
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
export const withdrawCash_ = [{
    type: 'input',
    name: 'ID',
    prop: 'applyId',
},{
    type: 'input',
    name: '交易ID',
    prop: 'transactionId',
},{
    type: 'input',
    name: '客户ID',
    prop: 'applyUser',
},{
    type: 'select',
    name: '审核状态',
    prop: 'status',
    postData: [{ label: '待审核', value: 0 },{label: '审核通过',value:1},{label: '审核拒绝',value:2},{label: '待录入',value:4},{label: '录入成功',value:5}],
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
}, {
    type: 'select',
    name: '状态',
    prop: 'step',
    postData: withdrawStatus,
},{
    type: 'datetime',
    name: '提现时间',
    prop: 'withdrawTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'applyTime',
},]
export const aggregation_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
}]
export const walletWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: '客户地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'select',
    name: '业务类型',
    prop: 'relationType',
    postData: relationType,
},]
export const accountWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},
...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
}]
export const balanceWallet_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},]
export const balanceAccount_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},]
export const accountTime_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '币种名称',
    prop: 'coin',
},{
    type: 'input',
    name: '钱包名称',
    prop: 'walletName',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
export const monitorRole = [
    {
        type: 'input',
        name: 'ID',
        prop: 'id',
    },{
        type: 'input',
        name: 'UID',
        prop: 'userId',
    },{
        type: 'select',
        name: '风险等级',
        prop: 'riskLevel',
        postData:[
            { label:  '低风险', value: 1 }, 
            { label:  '中风险', value: 2 }, 
            { label:  '高风险', value: 3 }
        ],
    },{
        type: 'select',
        name: '预警类型',
        prop: 'monitorCode',
        isPostApi: 'monitorAlarm',
        postURl: '',
        props:{
            label: 'monitorName',
            value: 'monitorCode'
        },
    },{
        type: 'select',
        name: '状态',
        prop: 'status',
        postData:[
            { label:  '未处理', value: 0 }, 
            { label:  '已处理', value: 1 }
        ],
    },{
        type: 'datetime',
        name: '预警时间',
        prop: 'alarmTime',
    },
]